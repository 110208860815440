<template>
  <section class="popup">
    <a href="/account" style="display: none" ref="dash"></a>
    <div class="popup__item">
      <div class="popup__header">
        <h6>Log In</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$store.state.AccessState = null"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__error" v-if="serverErrors">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="button"
          @click="closeErrorsHandler"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="currentColor"
          />
        </svg>
        <div class="popup__content">
          <p>{{ serverErrors }}</p>
          <p role="button" v-if="serverSrrorStatus" @click="handleErrorAction">
            Contact Support
          </p>
        </div>
      </div>
      <div class="popup__content">
        <form action="" @submit.prevent="handleSubmit">
          <div>
            <label for="">Email address or Phone number</label>
            <input
              type="text"
              name="email"
              placeholder="Email address or phone number"
              v-validate="'required|email'"
              v-model="loginData.email"
              :class="{ 'border-danger': errors.has('email') }"
            />
            <ValidationError
              v-show="errors.has('email')"
              :text="errors.first('email')"
            />
          </div>

          <div>
            <label for="">Password</label>
            <div class="pword_input">
              <input
                name="password"
                placeholder="Password"
                v-validate="'required'"
                v-model="loginData.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :class="{ 'border-danger': errors.has('password') }"
              />
              <span
                v-if="isPasswordVisible"
                @click="toggleVisiblity"
                class="corner"
              >
                <i class="fa-regular fa-eye"></i>
              </span>
              <span v-else @click="toggleVisiblity" class="corner"
                ><i class="fa-regular fa-eye-slash"></i
              ></span>
            </div>
            <ValidationError
              v-show="errors.has('password')"
              :text="errors.first('password')"
            />
          </div>
          <div class="remember">
            <span>
              <input type="checkbox" />
              Remember me
            </span>
            <p @click="$store.state.AccessState = 3">Forgot password?</p>
          </div>
          <PrimaryButton
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ Loading: isLoading }"
          >
            Sign In
          </PrimaryButton>
        </form>
        <!-- <button class="google">Continue with Google</button> -->
        <h6 class="new__account" @click="$store.state.AccessState = 2">
          Don’t have an account? Sign Up
        </h6>
      </div>
    </div>
  </section>
</template>

<script>
import api from "@/api";
import Recaptcha from "@/lib/captcha.mixin";

import { mapGetters } from "vuex";
import { success } from "@/lib/toast";
import { getCartItems } from "@/lib/utils";
import { SET_CART_AMOUNT, SET_CART_ITEMS } from "@/modules/cart/store";

export default {
  mixins: [Recaptcha],
  comments: {},
  data() {
    return {
      isLoading: false,
      serverErrors: null,
      serverSrrorStatus: null,
      isPasswordVisible: false,
      loginData: {
        email: "",
        password: "",
        recaptcha: "",
      },
    };
  },
  computed: {
    ...mapGetters("cartModule", ["cartItemsCount"]),
  },
  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;
        this.$validator.reset();
        this.closeErrorsHandler();

        // eslint-disable-next-line no-undef
        const captcha = await grecaptcha.enterprise.execute(
          process.env.VUE_APP_SITE_KEY,
          { action: "LOGIN" }
        );

        if (!captcha) {
          throw new Error("Unable to verify captcha. Please try again!");
        }

        this.loginData.recaptcha = captcha;

        const isValid = await this.$validator.validateAll();

        if (!isValid) return;

        const route = this.$route.query.redirectFrom || "/account";

        const req = await api.post("login", this.loginData);

        this.$store.state.AccessState = 0;
        this.$store.state.userDetails = req.data.data;

        window.sessionStorage.setItem(
          "userDetails",
          JSON.stringify(req.data.data)
        );

        success("Log in successful");
        this.$router.push(route);

        if (this.cartItemsCount === 0) {
          getCartItems();
          return;
        }

        const updatedResponse = await api.put(
          "cart/update-user-details",
          null,
          { allowDuplicateAuthorisation: true }
        );

        this.$store.commit(
          SET_CART_ITEMS,
          updatedResponse.data.data.cart_items
        );
        this.$store.commit(
          SET_CART_AMOUNT,
          updatedResponse.data.data.total_amount_to_be_paid
        );
      } catch (e) {
        const errorStatus = e.response?.data?.status?.toLowerCase();

        if (errorStatus === "banned" || errorStatus === "suspended") {
          this.serverSrrorStatus = e.response?.data?.status;
        }

        this.serverErrors = e.response?.data?.message || e.message;
      } finally {
        this.isLoading = false;
      }
    },
    toggleVisiblity() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    closeErrorsHandler() {
      this.serverErrors = null;
      this.serverSrrorStatus = null;
    },
    handleErrorAction() {
      if (this.serverSrrorStatus === "banned") {
        this.$store.state.AccessState = 6;
      } else if (this.serverSrrorStatus === "suspended") {
        this.$router("/appeal-suspension");
      }
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
button[disabled] {
  filter: opacity(0.5);
}
.pword_input {
  position: relative;
}
.pword_input input {
  position: relative;
}
.corner {
  position: absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
}
.popup__item {
  width: Min(90%, 444px);
  min-height: 300px;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 1rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__item::-webkit-scrollbar {
  display: none;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.5rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}

.popup__error {
  background-color: #e22f2f0f;
  border: 0.5px solid #e22f2f;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  margin: 1rem 0 2rem;
}

.popup__error svg {
  border-radius: 50%;
  background-color: #ea2020;
  color: #fff;
  font-size: 1.2rem;
  height: 1.6rem;
  width: 1.6rem;
  flex-shrink: 0;
}

.popup__error p {
  font-size: 1rem;
  color: #ff3d00cc;
  margin-left: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.popup__error p[role="button"] {
  padding: 0.2rem !important;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 0rem !important;
}

.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-size: 14px;
}
.remember span input {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.remember p {
  margin-bottom: unset;
  color: #006e52;
  font-size: 14px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
.remember p:hover {
  text-decoration: none;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease-in-out;
  margin-bottom: 1.5rem;
}
button[type="submit"]:hover {
  background-color: #01553f;
}
.google {
  background-color: transparent;
  border: 1px solid #006e52;
  color: #006e52;
  background-image: url("@/assets/icons/google.svg");
  background-repeat: no-repeat;
  background-position: 10% center;
  margin-bottom: 3rem;
}
.new__account {
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
</style>
