<template>
  <div class="for-you">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h6 class="header-text section" style="margin-bottom: 0 !important">
        For You
      </h6>
      <router-link class="primary-link" to="/products?filter=for_you"
        >See all</router-link
      >
    </div>
    <div class="container">
      <BlockLoader v-if="isLoading" />
      <SlickCarousel v-bind="settings" v-else>
        <ProductCard
          v-for="item in deals"
          :item="item"
          :refetch="refetch"
          :key="item.id"
        />
      </SlickCarousel>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/cards/ProductCard.vue";
import BlockLoader from "@/components/static/BlockLoader.vue";

export default {
  components: { ProductCard, BlockLoader },
  props: ["deals", "refetch", "isLoading"],
  data() {
    return {
      settings: {
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        touchThreshold: 3,
        centerMode: false,
        centerPadding: "0px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  setup() {
    // const { data, isLoading, refetch } = useQuery({
    //   queryKey: ["foryou"],
    //   queryFn: topDeals,
    //   retry: 1,
    //   keepPreviousData: true,
    // });
    // return { data, isLoading, refetch };
  },
};
</script>

<style scoped>
::deep(.slick-track) {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0.25rem !important;
  }
}
</style>
