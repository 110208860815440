<script setup>
import SpinnerSM from "@/layouts/spinnerSM.vue";
import useAddToCart from "@/composables/useAddToCart";
import useSavedItems from "@/composables/useSavedItems";
import store from "@/store";

import { computed, defineProps } from "vue";
import { RouterLink } from "vue-router";
import { formatNumber } from "@/lib/utils.js";
import { fail } from "@/lib/toast";

const props = defineProps(["item", "units", "refetch"]);

const { isProductInCart, isAddingToCart, addProductToCart } = useAddToCart(
  props.item,
  props.refetch
);
const { isSaving, saveProduct, deleteSaved, isDeleting } = useSavedItems();

const isItemInStock = props.item.quantity > 0;

const savedItem = computed(() => store.state.savedItems[props.item.uuid]);

const isProductSaved = computed(
  () => !!store.state.savedItems[props.item.uuid]
);

const isSavingProduct = computed(
  () =>
    isDeleting.value.includes(savedItem.value?.uuid) ||
    isSaving.value.includes(props.item.uuid)
);

const saveItemHandler = () => {
  if (!isProductSaved.value) {
    saveProduct(props.item.uuid);

    return;
  }

  if (!savedItem.value) {
    fail("Product is not saved");
    return;
  }

  deleteSaved(savedItem.value.uuid);
};
</script>

<template>
  <div class="card">
    <figure class="card__img" :class="{ outOfStock: !isItemInStock }">
      <img
        class="img-fluid lazyload"
        data-sizes="auto"
        :data-src="item.campaign_image"
        height="232"
        width="232"
        v-campaign-insights:views="item.uuid"
        v-if="item.campaign_image"
      />
      <img
        class="img-fluid lazyload"
        data-sizes="auto"
        :data-src="item.thumbnail"
        height="232"
        width="232"
        v-product-insights:views="item.uuid"
        v-else
      />
    </figure>
    <p class="card__outofstock" v-if="!isItemInStock">Out of Stock</p>
    <div
      class="card__save"
      v-if="!item.isSaved"
      role="button"
      :aria-label="`Save ${item.name}`"
      @click.stop="saveItemHandler"
      v-show="item.brand"
    >
      <SpinnerSM v-if="isSavingProduct" />
      <i v-else-if="isProductSaved" class="fi fi-ss-heart"></i>
      <i v-else class="fi fi-rs-heart"></i>
    </div>
    <div class="card__text" :class="{ outOfStock: !isItemInStock }">
      <div>
        <div class="product__src-info">
          <p v-if="item.brand">
            <i class="fi fi-sr-shop"></i>&nbsp;{{ item.brand }}
          </p>
          <h6 v-else>Services</h6>
          <p v-if="item.location">
            <i class="fi fi-sr-marker"></i>&nbsp;{{ item?.location?.city }},
            {{ item?.location?.state }}
          </p>
        </div>
        <h2>{{ item.name }}</h2>
      </div>
      <div>
        <h4 class="mt-1" v-if="item.vat_selling_price">
          ₦{{ formatNumber(item.vat_selling_price) }}
        </h4>
        <h2
          class="caption-text"
          v-if="`caption` in item"
          v-html="`${item.caption || `----`}`"
        ></h2>
        <div class="card__text__price">
          <div class="item">
            <h4 class="disabled mb-2" v-if="item.vat_actual_price">
              ₦{{ formatNumber(item.vat_actual_price) }}
            </h4>
            <a
              :href="item.url"
              target="__blank"
              v-if="item.url"
              style="color: inherit"
              >contact owner</a
            >
            <span style="display: flex; align-items: center">
              <h6>{{ item.total_views }} views</h6>
            </span>
          </div>
          <div
            class="cart"
            role="button"
            :aria-label="`Add ${item.name} to Cart`"
            v-if="isItemInStock"
            v-show="item.brand"
            @click.stop="addProductToCart(item)"
          >
            <SpinnerSM v-if="isAddingToCart" />
            <i
              v-else
              :class="
                isProductInCart
                  ? 'fi fi-rs-shopping-cart-check'
                  : 'fi fi-rr-shopping-cart'
              "
            ></i>
          </div>
        </div>
      </div>
    </div>
    <a :href="item.url" target="_blank" class="card__link" v-if="item.url"></a>
    <RouterLink
      :to="`/product/${item.slug}`"
      class="card__link"
      v-else
    ></RouterLink>
  </div>
</template>

<style scoped>
/* width="235" height="235" */
.card {
  position: relative;
  background-color: #fff;
  border: 1px solid #cce7d0;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  padding: 0.75rem;
  cursor: pointer;
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0 0 3px #ccc;
}

.card__link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.card__img {
  overflow: hidden;
  border-radius: calc(20px - 0.75rem);
  background-color: #f5f5f5;
}

.card__img.outOfStock {
  opacity: 0.5;
}

.card__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card__img img:hover {
  transition: all 0.5s ease-in-out;
  transform: scale3d(1.05, 1.05, 1.05) translateZ(0);
}
.card__outofstock {
  border-radius: 5px;
  background-color: #fdf3f3;
  color: #ea2020;
  position: absolute;
  top: 5%;
  left: 6%;
  z-index: 11;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  padding: 0.2rem !important;
}

.card__save {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9b00a;
  position: absolute;
  top: 4%;
  right: 6%;
  cursor: pointer;
  z-index: 11;
}

.card__text {
  padding: 10px 5px 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card__text.outOfStock {
  opacity: 0.5;
}

.card__text h6 {
  color: #90908e;
  font-size: 12px;
}
.card__text h2 {
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  height: 38px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  /* white-space: break-spaces; */
  text-align: left;
  color: #1a1a1a;
  margin: 0.35rem 0 0 0 !important;
}
.card__text__price {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.75rem;
  margin-top: 0.25rem;
}
h4 {
  font-size: 18px;
  font-weight: bold;
  color: #088178;
  margin-top: 7px;
}
.card__text__price .item h4 {
  font-size: 18px;
  font-weight: bold;
  color: #088178;
}
.card__text__price .item h4.disabled {
  font-size: 14px;
  font-weight: 400;
  color: #90908e;
  /* margin: 7px 0 0 7px; */
  text-decoration: line-through;
}
.card__text__price .cart {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f6ea;
  border: 1px solid #cce7d0;
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
  z-index: 11;
}
.card__text__price .cart .added {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8f6ea;
  border: 1px solid #cce7d0;
  cursor: pointer;
  flex-shrink: 0;
  visibility: hidden;
}

.caption-text {
  font-size: 0.875rem;
  font-weight: 300;
  width: 100%;
  height: unset;
  overflow-x: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.product__src-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.product__src-info p {
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
  color: #90908e;
}

.product__src-info p:first-child {
  margin-right: 0.3rem !important;
}

.product__src-info i {
  color: var(--primary-color);
}

@media screen and (max-width: 769px) {
  .card {
    padding: 0.35rem;
    border-radius: 15px;
  }

  .card__img {
    border-radius: calc(15px - 0.35rem);
  }
}
</style>
