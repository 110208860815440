export const SERVER_VALUES_MAP = {
  courier_plus: "CourierPlus",
  door_delivery: "Door Delivery",
  card_payment: "Card Payment",
};

export const STATUS_COLORS_MAP = {
  pending: "#FFA500",
  confirmed: "#00BFFF",
  cancelled: "#FF0000",
  delivered: "#006e52",
  shipped: "#1E90FF",
  out_for_delivery: "#FFD700",
  delivery_stocked: "#8A2BE2",
  returns: "#FF4500",
};
