import axios from "axios";

// Hey vercel

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

function onRequest(config) {
  const token = window.localStorage.getItem("token");
  const jwt = JSON.parse(window.localStorage.getItem("jwt"));

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  if (jwt) {
    config.headers["X-SellersMart-JWT"] = jwt.token;
    config.headers["X-SellersMart-JWT-passphrase"] = jwt.passphrase;
  }

  return clearDuplicateAuthorisations(token, jwt, config);
}

function onResponse(response) {
  const token = response?.data?.extra?.token;
  const jwtData =
    response.config.url === "generate-jwt" ? response?.data?.data : null;

  if (token) {
    window.localStorage.setItem("token", token);

    // TODO: Remove and let server terminate session
    window.sessionStorage.setItem("user", "authenticated");
  } else if (jwtData) {
    const data = JSON.stringify(response.data.data);

    window.localStorage.setItem("jwt", data);
  }

  return response;
}

function onError(error) {
  const status = error.response ? error.response.status : null;

  if (status === 401) {
    const token = window.localStorage.getItem("token");

    if (token) {
      window.localStorage.removeItem("token");
      window.sessionStorage.removeItem("user");
    } else {
      // TODO: Refactor access states & modal strategy
      // store.state.AccessState = 1;
    }
  }

  return Promise.reject(error);
}

/**
 * This function clears conflicting authorization headers in
 * the request configuration object unless
 * 'allowDuplicateAuthorisation' is set to true.
 *
 * Set 'allowDuplicateAuthorisation' to true for
 * requests that requires both authorisation headers
 */
function clearDuplicateAuthorisations(token, jwt, config) {
  if (config.allowDuplicateAuthorisation) return config;

  if (token) {
    delete config.headers["X-SellersMart-JWT"];
    delete config.headers["X-SellersMart-JWT-passphrase"];
  } else if (jwt) {
    delete config.headers["Authorization"];
  }

  return config;
}

api.interceptors.request.use(onRequest);
api.interceptors.response.use(onResponse, onError);

export default api;
