<template>
  <div>
    <div class="flash-pick product p-3 rounded-lg shadow-custom">
      <div
        class="flash-pick-top d-flex justify-content-between align-items-center"
      >
        <h6 class="header-text">Flash Pick of the day</h6>
        <router-link to="/products?promotion=Flash Sales">See All</router-link>
      </div>
      <SlickCarousel v-bind="settings">
        <div class="pick-info" v-for="item in products" :key="item.id">
          <div class="mb-3">
            <img
              :src="item.thumbnail"
              loading="lazy"
              style="width: 150px; height: 150px"
              :alt="item.name"
            />
          </div>
          <div class="product__src-info">
            <p v-if="item.brand">
              <i class="fi fi-sr-shop"></i>&nbsp;{{ item.brand }}
            </p>
            <h6 v-else>Services</h6>
            <p v-if="item.location">
              <i class="fi fi-sr-marker"></i>&nbsp;{{ item?.location?.city }},
              {{ item?.location?.state }}
            </p>
          </div>
          <h6 class="product-name">{{ item.name }}</h6>
          <h6 class="product-price">
            {{ formatPrice(item.vat_selling_price) }}
          </h6>
          <small class="text-muted">{{ item.quantity }} Items Left</small>
          <div class="mt-2">
            <el-progress
              :percentage="40"
              :color="customColor"
              :show-text="false"
            ></el-progress>
          </div>
        </div>
      </SlickCarousel>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/lib/utils";

export default {
  props: ["products"],
  methods: {
    formatPrice,
  },
  data() {
    return {
      customColor: "#F9B00A",
      settings: {
        // autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 3,
        centerMode: false,
        centerPadding: "0px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
.flashpick .flash-pick .slick-slide {
  flex-shrink: 1;
}
</style>

<style scoped>
.flash-pick img {
  height: 10rem;
  width: 100%;
  object-fit: contain;
}

.flash-pick h4 {
  font-family: THICCCBOI;
  font-size: Max(1.5rem, 16px);
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  margin-bottom: 1rem !important;
}
.flash-pick h6 {
  font-family: THICCCBOI;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  margin-bottom: 0.3rem !important;
}
.flash-pick span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem !important;
  cursor: pointer;
}
.flash-pick span p {
  flex-shrink: 0;
}

.product__src-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.product__src-info p {
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.8rem;
  color: #90908e;
}

.product__src-info p:first-child {
  margin-right: 0.3rem !important;
}

.product__src-info i {
  color: var(--primary-color);
}

@media screen and (max-width: 769px) {
  .flash-pick {
    width: 100%;
  }
  .pick-info {
    text-align: center;
  }
}
</style>
