import Home from "../pages/indexPage.vue";

const ProductID = () => import("../pages/details/productId.vue");
const ProductReviews = () => import("../pages/details/productReviews.vue");
const StoreListing = () => import("../pages/storeListing.vue");
const StoreID = () => import("../pages/details/storeId.vue");
const ProductListing = () => import("../pages/productListing.vue");
const ProductCategory = () => import("../pages/productCategory.vue");
const TermsOfService = () => import("../pages/TermsOfService.vue");
const PrivacyPolicy = () => import("../pages/PrivacyPolicy.vue");
const ProductCategoryList = () => import("../pages/categoryListing.vue");
const VendorProductListing = () => import("../pages/VendorProductListing.vue");
const Cars = () => import("../pages/Cars/index.vue");
const CarDetails = () => import("../pages/Cars/_id.vue");
// const VendorListing = () => import("../pages/VendorListing.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/products",
    name: "all-products",
    component: ProductListing,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/product/:id",
    name: "product-details",
    props: true,
    component: ProductID,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/product/:id/reviews",
    name: "product-reviews",
    props: true,
    component: ProductReviews,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/categories",
    name: "product-category-list",
    component: ProductCategoryList,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/category/:category",
    name: "product-category",
    props: true,
    component: ProductCategory,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/store-listing",
    name: "store-listing",
    component: StoreListing,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/store/:id",
    name: "store-details",
    component: StoreID,
    props: true,
    meta: {
      layout: "homeLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/vendor/:name",
    name: "vendors products",
    component: VendorProductListing,
    props: true,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: TermsOfService,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/cars",
    name: "car lot",
    component: Cars,
    meta: {
      layout: "homeLayout",
    },
  },
  {
    path: "/cars/:id",
    name: "Car Details",
    props: true,
    component: CarDetails,
    meta: {
      layout: "homeLayout",
    },
  },
];

export default routes;
