<template>
  <Fragment v-if="products?.length && isSaleOn">
    <figure class="banner" v-show="bannerImage">
      <img :src="bannerImage" :alt="label" />
    </figure>
    <div
      class="sale"
      :class="{ countdown: !!promotionDateRange[0] && !!promotionDateRange[1] }"
      :style="{ backgroundColor: accentColor }"
    >
      <div
        class="sale-head"
        :style="{ backgroundColor: primaryColor, color: textColor }"
      >
        <div class="label">
          <h6>
            {{ label }}
          </h6>
          <img :src="image" :alt="label" v-if="image" />
        </div>
        <h6
          class="countdown"
          v-if="promotionDateRange[0] && promotionDateRange[1]"
        >
          Time Left:
          <span>
            {{ countdown.days }}d : {{ countdown.hours }}h :
            {{ countdown.minutes }}m : {{ countdown.seconds }}s
          </span>
        </h6>
        <router-link :to="`/products?promotion=${promotion}`">
          <span>View All</span>
          <i-icon icon="fa6-solid:angle-right" />
        </router-link>
      </div>
      <div class="container products">
        <SlickCarousel v-bind="settings">
          <ProductCard
            v-for="item in products"
            :item="item"
            :refetch="refetch"
            :key="item.id"
          />
        </SlickCarousel>
      </div>
    </div>
  </Fragment>
</template>

<script setup>
import api from "@/api";
import ProductCard from "@/components/cards/ProductCard.vue";

import { useQuery } from "@tanstack/vue-query";
import { Fragment } from "vue-frag";
import {
  onBeforeUnmount,
  computed,
  defineProps,
  onMounted,
  watch,
  reactive,
} from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "Sale",
  },
  image: String,
  slug: String,
  primaryColor: String,
  accentColor: String,
  textColor: String,
  promotion: String,
});

let timer;
const countdown = reactive({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
});

const products = computed(() => data.value?.data?.products || []);

const promotionDateRange = computed(() => {
  const promotion = data.value?.data?.promotion;
  const startDate = promotion?.start_date || "2024-10-26";
  const endDate = promotion?.end_date || "2024-11-05";

  return [startDate, endDate];
});

const isSaleOn = computed(() => {
  if (!promotionDateRange.value[1]) return true;

  return new Date() <= new Date(promotionDateRange.value[1]);
});

const bannerImage = computed(() => {
  const promotion = data.value?.data?.promotion;

  if (window.innerWidth < 760 && promotion?.banner_image_sm) {
    return promotion?.banner_image_sm;
  }

  return promotion?.banner_image;
});

const calculateTimeLeft = () => {
  const endDate = promotionDateRange.value[1];

  const now = new Date();
  const timeLeft = new Date(endDate) - now;

  if (timeLeft > 0) {
    countdown.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    countdown.hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
    countdown.minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    countdown.seconds = Math.floor((timeLeft / 1000) % 60);
  } else {
    countdown.days = 0;
    countdown.hours = 0;
    countdown.minutes = 0;
    countdown.seconds = 0;
  }
};

const { data, refetch } = useQuery({
  queryKey: [props.promotion],
  queryFn: getProducts,
});

async function getProducts({ queryKey }) {
  const url = `/promotion-products?promotion_type=${queryKey[0]}&per_page=10&page=1`;

  try {
    const response = await api.get(url);

    return response.data;
  } catch (err) {
    // Log errors
    return null;
  }
}

const startTimer = () => {
  if (timer) {
    clearInterval(timer);
  }

  calculateTimeLeft();
  timer = setInterval(calculateTimeLeft, 1000);
};

watch(promotionDateRange, ([startDate, endDate]) => {
  if (startDate && endDate) {
    startTimer();
  }
});

onMounted(() => {
  const [startDate, endDate] = promotionDateRange.value;
  if (startDate && endDate) {
    startTimer();
  }
});

onBeforeUnmount(() => {
  if (timer) {
    clearInterval(timer);
  }
});

onMounted(() => {
  const [startDate, endDate] = promotionDateRange.value;

  if (!startDate || !endDate) return;

  calculateTimeLeft();
  timer = setInterval(calculateTimeLeft, 1000);
});

const settings = {
  autoplay: true,
  focusOnSelect: true,
  infinite: true,
  speed: 800,
  slidesToShow: 5,
  slidesToScroll: 1,
  touchThreshold: 3,
  centerMode: false,
  centerPadding: "0px",
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
</script>

<style scoped>
.sale-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.4rem;
  position: relative;
}

.sale-head .label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.sale-head .label img {
  object-fit: contain;
  height: 2rem;
  width: 2rem;
}

.sale-head h6 {
  font-size: 1.2rem;
  font-weight: 400;
  font-weight: 700;
}

.sale-head h6.countdown {
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sale-head h6.countdown span {
  font-weight: 700;
}

.sale-head a {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 5px;
  text-decoration: none;
  color: currentcolor;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.products {
  padding: 1.6rem 0rem;
}

::deep(.slick-track) {
  display: none;
}

.banner {
  margin-bottom: 1rem;
  margin-top: 0 !important;
}

.banner img {
  width: 100%;
}

@media only screen and (max-width: 980px) {
  .sale.countdown {
    margin-top: 40px;
  }

  .sale-head h6.countdown {
    background-color: #f70002;
    text-align: center;
    width: 100%;
    top: 0%;
    color: #fff;
    padding: 0.3rem !important;
    transform: translate(-50%, -100%);
    font-size: 1rem;
  }

  .sale-head {
    padding: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .sale-head {
    padding: 0.5rem 1rem;
  }

  .products {
    padding: 1.2rem 0;
  }
}
</style>
