import axios from "axios";

import { useQuery } from "@tanstack/vue-query";

function useCampaigns(selector) {
  async function getCampaingData() {
    try {
      const audienceResponse = await axios.get("campaigns/audiences/all");
      const audienceId = await audienceResponse.data.data[selector]?.uuid;

      const campaignsResponse = await axios.get(`campaigns/${audienceId}`);
      const data = campaignsResponse.data.data;

      return data;
    } catch (err) {
      // Report Errors to analytics
    }
  }

  const query = useQuery({
    queryKey: ["campaignData"],
    queryFn: getCampaingData,
    retry: 1,
    keepPreviousData: true,
  });

  return query;
}

export default useCampaigns;
