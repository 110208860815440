<template>
  <section class="popup">
    <section class="popup__one" v-if="steps === 1">
      <h2>Pin Settings</h2>
      <div class="popup__list">
        <span @click="steps = 2">
          <input type="radio" id="specifyColor" />
          <p for="" class="mb-0">Create Pin</p>
        </span>
        <span @click="steps = 3">
          <input type="radio" id="specifyColor" />
          <p for="" class="mb-0">Reset Pin</p>
        </span>
      </div>
      <button
        class="back"
        @click="$store.state.AccessState = null"
        style="margin-top: 3rem"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Go Back</p>
      </button>
    </section>

    <section class="popup__one" v-if="steps === 2">
      <h2>Set new pin</h2>
      <form action="" @submit.prevent="handleSubmit">
        <label for="">New Pin</label>
        <input
          type="number"
          placeholder="eg: 1234"
          v-model="userInfo.new_pin"
          required
        />
        <button type="submit" :disabled="loading" :class="{ Loading: loading }">
          <p v-if="loading"><Spinner /></p>
          <p v-else>Create Pin</p>
        </button>
      </form>
      <div class="back" @click="$store.state.AccessState = null">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Go Back</p>
      </div>
    </section>

    <section class="popup__one" v-if="steps === 3">
      <h2>Reset pin</h2>
      <form action="" @submit.prevent="handleReset">
        <label for="">New Pin</label>
        <input
          type="number"
          placeholder="eg: 1234"
          v-model="recentInfo.new_pin"
          required
        />
        <label for="">Old Pin</label>
        <input
          type="number"
          placeholder="eg: 1234"
          v-model="recentInfo.old_pin"
          required
        />
        <PrimaryButton
          :loading="loading"
          :disabled="loading"
          :class="{ Loading: loading }"
        >
          Create Pin
        </PrimaryButton>
      </form>
      <div class="back" @click="$store.state.AccessState = null">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Go Back</p>
      </div>
    </section>

    <section class="popup__one" v-if="steps === 4">
      <h2>Set new pin</h2>
      <h6 class="reset">Your pin has been successfully created.</h6>
      <img
        src="@/assets/img/completed.svg"
        loading="lazy"
        alt=""
        height="168"
        width="168"
      />
      <button @click="$store.state.AccessState = null">Continue</button>
      <div class="back" @click="$store.state.AccessState = null">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Close</p>
      </div>
    </section>
  </section>
</template>

<script setup>
import { useQuery } from "@tanstack/vue-query";
import { fail, success } from "@/lib/toast";
import { onMounted, ref } from "vue";
import Spinner from "../layouts/spinner.vue";
import api from "@/api";

const userInfo = ref({
  new_pin: "",
});
const recentInfo = ref({
  new_pin: "",
  old_pin: "",
});
const steps = ref(1);

const loading = ref(false);

const wallet = async () => {
  let res = await api.get("spend-and-save-wallet");
  return res.data.data;
};

const { data } = useQuery({
  queryKey: ["userProfile"],
  queryFn: wallet,
});

onMounted(() => {
  if (data.isPinAvailable) {
    steps.value = 3;
  } else if (!data.isPinAvailable) {
    steps.value = 2;
  }
});

// submit form
const handleSubmit = async () => {
  loading.value = true;
  try {
    await api.post("wallet/pin", userInfo.value);
    loading.value = false;
    success("Pin created successdully");
    steps.value = 4;
  } catch (e) {
    fail(e.response.data.message);
    loading.value = false;
  }
};

const handleReset = async () => {
  loading.value = true;
  try {
    await api.post("wallet/reset-pin", recentInfo.value);
    loading.value = false;
    success("Pin reset successful");
    steps.value = 4;
  } catch (e) {
    fail(e.response.data.message);
    loading.value = false;
  }
};
</script>

<style scoped>
.popup__list span {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.popup__list span input {
  width: 30px;
  height: 30px;
}
.popup__list span p {
  font-family: THICCCBOI;
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-top: 1rem;
}
.pack p {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
button[disabled] {
  filter: opacity(0.5);
}
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
.popup__item,
.popup__one {
  width: Min(90%, 547px);
  min-height: 300px;
  max-height: 90vh;
  overflow: auto;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 3rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__item::-webkit-scrollbar,
.popup__one::-webkit-scrollbar {
  display: none;
}
.popup__one {
  text-align: center;
}
.popup__one h2 {
  text-align: center;
  font-family: THICCCBOI;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  margin-bottom: 1.3rem !important;
}
.popup__one h6 {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0.5rem !important;
}
.popup__one h5 {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 1rem 0 !important;
}
.resend {
  color: #006e52;
}
.popup__one h6 span {
  color: #006e52;
  cursor: pointer;
}
form {
  margin-top: 1rem;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.emailverify {
  margin: 1rem 0;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}
.remember span input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.remember h6 {
  margin-bottom: unset;
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
  text-align: left;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.new__account {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2rem;
}
.new__account span {
  color: #006e52;
  cursor: pointer;
}
.terms {
  width: Min(373px, 100%);
  text-align: center;
  margin: 1.5rem auto !important;
}
.reset {
  width: Min(381px, 100%);
  margin: 1rem auto !important;
}
</style>
