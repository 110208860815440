<template>
  <Fragment>
    <SmartCampaign v-if="!data?.data?.length" />
    <FlashPick v-else :products="data?.data" />
  </Fragment>
</template>

<script setup>
import api from "@/api";
import SmartCampaign from "./SmartCampaign.vue";
import FlashPick from "./FlashPick.vue";

import { Fragment } from "vue-frag";
import { useQuery } from "@tanstack/vue-query";

const getProducts = async () => {
  const url = `/promotion-products?promotion_type=Flash Sales&per_page=10&page=1`;

  try {
    const response = await api.get(url);

    return response.data;
  } catch (err) {
    // Log errors
  }
};

const { data } = useQuery({
  queryKey: ["Flash Sales"],
  queryFn: getProducts,
  retry: 1,
  keepPreviousData: true,
});
</script>
