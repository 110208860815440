<template>
  <section class="popup">
    <a href="/" style="display: none" ref="dash"></a>
    <section class="popup__one" v-if="steps === 1">
      <h2>Change password</h2>
      <h6 class="reset">
        Your new password must be different from previously used passwords.
      </h6>
      <form action="" @submit.prevent="handleSubmit">
        <label for="">Current Password</label>
        <div class="pword_input">
          <input
            :type="visibled ? 'text' : 'password'"
            placeholder="password"
            v-model="userInfo.current_password"
            required
          />
          <span v-if="visibled" @click="toggleVisiblity" class="corner">
            <i class="fa-regular fa-eye"></i>
          </span>
          <span v-else @click="toggleVisiblity" class="corner"
            ><i class="fa-regular fa-eye-slash"></i
          ></span>
        </div>
        <label for="">New Password</label>
        <div class="pword_input">
          <input
            :type="visible ? 'text' : 'password'"
            placeholder="password"
            v-model="userInfo.new_password"
            required
          />
          <span v-if="visible" @click="toggleSecondVisiblity" class="corner">
            <i class="fa-regular fa-eye"></i>
          </span>
          <span v-else @click="toggleSecondVisiblity" class="corner"
            ><i class="fa-regular fa-eye-slash"></i
          ></span>
        </div>
        <label for="">Confirm New Password</label>
        <div class="pword_input">
          <input
            :type="visiblethree ? 'text' : 'password'"
            placeholder="Confirm Password"
            v-model="userInfo.new_password_confirmation"
            required
          />
          <span v-if="visible" @click="toggleThirdVisiblity" class="corner">
            <i class="fa-regular fa-eye"></i>
          </span>
          <span v-else @click="toggleThirdVisiblity" class="corner"
            ><i class="fa-regular fa-eye-slash"></i
          ></span>
        </div>
        <button type="submit" :disabled="loading" :class="{ Loading: loading }">
          <p v-if="loading"><Spinner /></p>
          <p v-else>Change Password</p>
        </button>
      </form>
      <div class="back" @click="$store.state.AccessState = 1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Back to log in</p>
      </div>
    </section>

    <section class="popup__one" v-if="steps === 2">
      <h2>Change Password</h2>
      <h6 class="reset">
        Your password has been changed successfully. Click below to login.
      </h6>
      <img
        src="@/assets/img/completed.svg"
        loading="lazy"
        alt=""
        height="168"
        width="168"
      />
      <button @click="logout">Continue</button>
      <div class="back" @click="$store.state.AccessState = 0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p>Close</p>
      </div>
    </section>
  </section>
</template>

<script setup>
import { fail, success } from "@/lib/toast";
import axios from "axios";
import { ref } from "vue";
import Spinner from "../layouts/spinner.vue";
import store from "@/store";

const visibled = ref(false);
const visible = ref(false);
const visiblethree = ref(false);

const toggleVisiblity = () => {
  visibled.value = !visibled.value;
};

const toggleSecondVisiblity = () => {
  visible.value = !visible.value;
};

const toggleThirdVisiblity = () => {
  visiblethree.value = !visiblethree.value;
};

const userInfo = ref({
  current_password: "",
  new_password: "",
  new_password_confirmation: "",
});
const steps = ref(1);
const dash = ref(null);

const loading = ref(false);

const logout = () => {
  window.localStorage.setItem("token", null);
  window.sessionStorage.setItem("user", null);
  store.state.AccessState = 1;
  store.state.logout = false;
};

// submit form
const handleSubmit = async () => {
  loading.value = true;
  try {
    await axios.put("user/change-password", userInfo.value);
    loading.value = false;
    success("Password successfully changed. Please log in again");
    logout();
  } catch (e) {
    fail(e.response.data.message);
    loading.value = false;
  }
};
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-top: 1rem;
}
.pack p {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
button[disabled] {
  filter: opacity(0.5);
}
.pword_input {
  position: relative;
}
.pword_input input {
  position: relative;
}
.corner {
  position: absolute;
  right: 5%;
  top: 30%;
  cursor: pointer;
}
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
.popup__item,
.popup__one {
  width: Min(90%, 547px);
  min-height: 300px;
  max-height: 90vh;
  overflow: auto;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 3rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__item::-webkit-scrollbar,
.popup__one::-webkit-scrollbar {
  display: none;
}
.popup__one {
  text-align: center;
}
.popup__one h2 {
  text-align: center;
  font-family: THICCCBOI;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  margin-bottom: 1.3rem !important;
}
.popup__one h6 {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0.5rem !important;
}
.popup__one h5 {
  font-family: THICCCBOI;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 1rem 0 !important;
}
.resend {
  color: #006e52;
}
.popup__one h6 span {
  color: #006e52;
  cursor: pointer;
}
form {
  margin-top: 1rem;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.emailverify {
  margin: 1rem 0;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}
.remember span input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.remember h6 {
  margin-bottom: unset;
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
  text-align: left;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.new__account {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2rem;
}
.new__account span {
  color: #006e52;
  cursor: pointer;
}
.terms {
  width: Min(373px, 100%);
  text-align: center;
  margin: 1.5rem auto !important;
}
.reset {
  width: Min(381px, 100%);
  margin: 1rem auto !important;
}
</style>
