<script setup>
import { useQuery } from "@tanstack/vue-query";
import axios from "axios";
import { ref, watchEffect } from "vue";

const fetchAudiences = async () => {
  let res = await axios.get("campaigns/audiences/all");
  return res.data.data;
};

const firstCampaign = ref(null);
const secondCampaign = ref(null);

const audQuery = useQuery({
  queryKey: ["userCampaigns"],
  queryFn: fetchAudiences,
});

const campaigns = async () => {
  let res = await axios.get(`campaigns/${audQuery.data.value[1].uuid}`);
  return res.data.data;
};

// eslint-disable-next-line no-unused-vars
const campaignQuery = useQuery({
  queryKey: ["campaigns"],
  queryFn: campaigns,
});

// Function to get two random, different items from an array
const getTwoRandomItems = (array) => {
  if (campaignQuery.data.value.length < 2)
    return [campaignQuery.data.value[0] || null, null];

  const firstIndex = Math.floor(
    Math.random() * campaignQuery.data.value.length
  );
  let secondIndex;
  do {
    secondIndex = Math.floor(Math.random() * campaignQuery.data.value.length);
  } while (secondIndex === firstIndex);

  return [array[firstIndex], array[secondIndex]];
};

watchEffect(() => {
  if (campaignQuery.data.value && campaignQuery.data.value.length > 1) {
    const [first, second] = getTwoRandomItems(campaignQuery.data.value);
    firstCampaign.value = first;
    secondCampaign.value = second;
  } else {
    firstCampaign.value = null;
    secondCampaign.value = null;
  }
});

setInterval(() => {
  if (campaignQuery.data.value && campaignQuery.data.value.length > 1) {
    const [first, second] = getTwoRandomItems(campaignQuery.data.value);
    firstCampaign.value = first;
    secondCampaign.value = second;
  } else {
    firstCampaign.value = null;
    secondCampaign.value = null;
  }
}, 30000);
</script>

<template>
  <div class="campaign-cards">
    <div
      class="campaign-card"
      style="cursor: pointer"
      v-if="firstCampaign"
      v-campaign-insights:views="firstCampaign.uuid"
      @click="firstCampaign.url"
    >
      <img
        :src="firstCampaign.campaign_image"
        alt=""
        class="lazyload campaign-img"
        data-sizes="auto"
      />
      <div class="card-content">
        <h5>{{ firstCampaign.name }}</h5>
        <h3>{{ firstCampaign.headline }}</h3>
        <router-link :to="firstCampaign.url" class="shop-now">
          Contact Now &rarr;
        </router-link>
      </div>
    </div>
    <div
      class="campaign-card"
      style="cursor: pointer"
      @click="redirectToVendor"
      v-else
    >
      <img
        data-src="https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719244580/Reach_Millions_Website_page_uqmavq.jpg"
        alt=""
        class="lazyload campaign-img"
        data-sizes="auto"
      />
      <div class="card-content d-none">
        <h5>Electronics</h5>
        <h3>Save 17% on Electronics</h3>
        <router-link
          to="/category/14fcf3f9-b6df-4e86-9673-a254bc3b1a8d"
          class="shop-now"
        >
          Shop Now &rarr;
        </router-link>
      </div>
    </div>

    <div
      class="campaign-card"
      style="cursor: pointer"
      v-if="secondCampaign"
      v-campaign-insights:views="secondCampaign.uuid"
      @click="secondCampaign.url"
    >
      <img
        :src="secondCampaign.campaign_image"
        alt=""
        class="lazyload campaign-img"
        data-sizes="auto"
      />
      <div class="card-content">
        <h5>{{ secondCampaign.name }}</h5>
        <h3>{{ secondCampaign.headline }}</h3>
        <router-link :to="secondCampaign.url" class="shop-now">
          Contact Now →
        </router-link>
      </div>
    </div>
    <div
      class="campaign-card"
      style="cursor: pointer"
      v-else
      @click="redirectToAffiliate"
    >
      <img
        data-src="https://res.cloudinary.com/dzkh9cyq4/image/upload/q_auto:low,f_auto/v1719244579/20240624_161541_quk86y.jpg"
        alt=""
        class="lazyload campaign-img"
        data-sizes="auto"
      />
      <div class="card-content d-none">
        <h5>Fashion</h5>
        <h3>Save 17% on Fashion</h3>
        <router-link
          to="/category/1333dc66-00fb-427d-9fb8-61cb2b2c36cd"
          class="shop-now"
        >
          Shop Now →
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  methods: {
    redirectToAffiliate() {
      window.open("https://affiliates.sellersmart.ng", "_blank");
    },
    redirectToVendor() {
      window.open("https://vendors.sellersmart.ng", "_blank");
    },
  },
};
</script>

<style scoped>
.campaign-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.campaign-card img {
  width: 100%;
}

.campaign-card {
  position: relative;
  border-radius: 0.7rem;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  aspect-ratio: 2 / 1;
}

.campaign-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.7rem;
  position: relative;
}

.campaign-card img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  color: #fff;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.9);
}

.card-content h5 {
  margin: 0;
  font-size: 0.7rem;
  opacity: 0.7;
}

.card-content h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.card-content .shop-now {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  text-decoration: underline;
  margin-top: 0.5rem;
}

.campaign-card:hover::before {
  background: rgba(0, 0, 0, 0.7);
}

.campaign-card:hover {
  transform: scale(1.03);
}

@media screen and (max-width: 769px) {
  .campaign-cards {
    flex-direction: row;
    gap: 0.5rem;
    aspect-ratio: 4 / 1;
  }

  .card-content h3 {
    font-size: 1.25rem;
  }
}
</style>
