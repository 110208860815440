<template>
  <div class="best-selling">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h6 class="header-text section" style="margin-bottom: 0 !important">
        Best Selling
      </h6>
      <router-link class="primary-link" to="/products?filter=best_selling"
        >See all</router-link
      >
    </div>
    <div class="containr px-1">
      <BlockLoader v-if="isLoading" />
      <SlickCarousel v-bind="settings" v-else>
        <ProductCard
          v-for="item in deals"
          :item="item"
          :refetch="refetch"
          :key="item"
          class="mr-3"
        />
      </SlickCarousel>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/cards/ProductCard.vue";
import BlockLoader from "@/components/static/BlockLoader.vue";

export default {
  components: {
    ProductCard,
    BlockLoader,
  },
  props: ["deals", "refetch", "isLoading"],
  data() {
    return {
      settings: {
        // dots: true,
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        touchThreshold: 5,
        centerMode: false,
        centerPadding: "12px",
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
:deep(.shimmer) {
  background: #d7d5d1;
}
</style>
