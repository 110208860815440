<template>
  <section class="popup">
    <section class="popup__one" v-if="steps === 1">
      <div class="mb-4">
        <h2>Forgot password?</h2>
        <p>No worries, we’ll send you reset instructions.</p>
      </div>
      <form action="" @submit.prevent="handleSubmit">
        <div>
          <label for="">Email address</label>
          <input
            type="email"
            placeholder="Your registered email address"
            v-model="userInfo.email"
            name="email"
            v-validate="'required|email'"
            :class="{ 'border-danger': errors.has('email') }"
          />
          <ValidationError
            v-show="errors.has('email')"
            :text="errors.first('email')"
          />
        </div>
        <PrimaryButton
          :loading="loading"
          :disabled="loading"
          :class="{ Loading: loading }"
        >
          Request password reset
        </PrimaryButton>
      </form>
      <div class="back align-items-end" @click="$store.state.AccessState = 1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p style="cursor: pointer">Back to log in</p>
      </div>
    </section>

    <section class="popup__one" v-if="steps === 2">
      <h2>Check your email</h2>
      <p class="reset">
        We have sent a password reset link to {{ maskStr(userInfo.email) }}
      </p>
      <img
        src="@/assets/img/gmail.svg"
        loading="lazy"
        alt=""
        height="131"
        width="144"
        style="margin: 1rem auto; display: flex; justify-content: center"
      />
      <button @click="openMail">Open email app</button>
      <p class="new__account">
        Didn’t receive the email?
        <span>Click to resend</span>
      </p>
      <div class="back align-items-end" @click="$store.state.AccessState = 1">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.9987 16.6654L3.33203 9.9987L9.9987 3.33203L11.1862 4.4987L6.51953 9.16536H16.6654V10.832H6.51953L11.1862 15.4987L9.9987 16.6654Z"
            fill="black"
          />
        </svg>

        <p style="cursor: pointer">Back to log in</p>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { fail, success } from "@/lib/toast";
export default {
  data() {
    return {
      loading: false,
      steps: 1,
      userInfo: {
        email: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      await this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.loading = true;
          try {
            await axios.post("password/request-reset-link", this.userInfo);
            this.loading = false;
            success("Link sent successful");
            this.steps = this.steps + 1;
          } catch (e) {
            fail(e.response.data.message);
            this.loading = false;
          }
        }
      });
    },
    maskStr(str) {
      return str.replace(/(?<=.{3}).(?=[^@]*?@)/g, "*");
    },
    openMail() {
      const mailtoLink = "mailto:";
      window.open(mailtoLink);
    },
  },
};
</script>

<style scoped>
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-top: 1rem;
}
button[disabled] {
  filter: opacity(0.5);
}
.back p {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
.popup__item,
.popup__one {
  width: Min(90%, 444px);
  min-height: 300px;
  max-height: 90vh;
  overflow: auto;
  padding: 2rem 2rem !important;
  border: 0.5px;
  background-color: #ffffff;
  padding: 3rem;
  position: absolute;
  z-index: 15;
  border-radius: 1rem;
}
.popup__item::-webkit-scrollbar,
.popup__one::-webkit-scrollbar {
  display: none;
}
.popup__one h2 {
  text-align: center;
  font-family: var(--primary-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  margin-bottom: 0.7rem !important;
}
.popup__one p {
  font-family: var(--primary-font-family);
  font-size: 14px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 0.5rem !important;
}
.popup__one h5 {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin: 1rem 0 !important;
}
.resend {
  color: #006e52;
}
.popup__one h6 span {
  color: #006e52;
  cursor: pointer;
}
form {
  margin-top: 1rem;
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
.emailverify {
  margin: 1rem 0;
}
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.remember span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}
.remember span input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.remember h6 {
  margin-bottom: unset;
  color: #006e52;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: underline;
  cursor: pointer;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
  text-align: left;
}
input {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}
input::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75);
}
button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 1.5rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.new__account {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2rem;
}
.new__account span {
  color: #006e52;
  cursor: pointer;
}
.terms {
  width: Min(373px, 100%);
  text-align: center;
  margin: 1.5rem auto !important;
}
.reset {
  width: Min(381px, 100%);
  margin: 1rem auto !important;
}
</style>
