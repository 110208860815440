import Vue from "vue";
import cartModuleActions from "./actions";
import { variantsAsCartItems } from "@/lib/utils";

// Mutations
export const SET_CART_ITEMS = "cartModule/SET_CART_ITEMS";
export const SET_CART_AMOUNT = "cartModule/SET_CART_AMOUNT";
export const RESET_CART = "cartModule/RESET_CART";
export const SELECT_PRODUCT_VARIANT = "cartModule/SELECT_PRODUCT_VARIANT";
export const TOGGLE_IS_ADDING_PRODUCT = "cartModule/TOGGLE_IS_ADDING_PRODUCT";
export const SET_IS_LOADING_CART = "cartModule/SET_IS_LOADING_CART";

// Getters
export const CART_ITEMS_COUNT = "cartModule/cartItemsCount";
export const CART_REFERENCE_IDS = "cartModule/groupReferenceIds";

// Mutations - LOCAL
export const _SET_CART_ITEMS = SET_CART_ITEMS.replace("cartModule/", "");
export const _SET_CART_AMOUNT = SET_CART_AMOUNT.replace("cartModule/", "");
export const _RESET_CART = RESET_CART.replace("cartModule/", "");
export const _SELECT_PRODUCT_VARIANT = SELECT_PRODUCT_VARIANT.replace(
  "cartModule/",
  ""
);
export const _TOGGLE_IS_ADDING_PRODUCT = TOGGLE_IS_ADDING_PRODUCT.replace(
  "cartModule/",
  ""
);
export const _SET_IS_LOADING_CART = SET_IS_LOADING_CART.replace(
  "cartModule/",
  ""
);

// Getters - LOCAL
export const _CART_ITEMS_COUNT = CART_ITEMS_COUNT.replace("cartModule/", "");
export const _CART_REFERENCE_IDS = CART_REFERENCE_IDS.replace(
  "cartModule/",
  ""
);

const CartModule = {
  namespaced: true,
  state: {
    totalAmount: 0,
    items: {},
    addingItemsMap: {},
    selectProductVariant: null,
    isLoadingCart: false,
  },
  getters: {
    [_CART_ITEMS_COUNT]: (state) => Object.keys(state.items).length,
    isAddingProduct: (state) => (productId) => {
      return state.addingItemsMap[productId] !== undefined;
    },
    [_CART_REFERENCE_IDS]: (state) => {
      const refs = Array.from(
        new Set(
          Object.values(state.items).map((item) => item.group_reference_id)
        )
      );

      return refs;
    },
  },
  mutations: {
    [_SET_CART_ITEMS](state, items) {
      const cartItems = variantsAsCartItems(items);
      const cartSnapshot = {};

      cartItems.forEach((item) => {
        cartSnapshot[item.id] = item;
      });

      state.items = cartSnapshot;
    },
    [_SET_CART_AMOUNT](state, amount) {
      state.totalAmount = amount;
    },
    [_RESET_CART](state) {
      state.totalAmount = 0;
      state.items = {};
    },
    [_SELECT_PRODUCT_VARIANT](state, product) {
      state.selectProductVariant = product;
    },
    [_TOGGLE_IS_ADDING_PRODUCT](state, productId) {
      if (state.addingItemsMap[productId]) {
        Vue.delete(state.addingItemsMap, productId);
      } else {
        Vue.set(state.addingItemsMap, productId, true);
      }
    },
    [_SET_IS_LOADING_CART](state, value) {
      Vue.set(state, "isLoadingCart", value);
    },
  },
  actions: cartModuleActions,
};

export default CartModule;
