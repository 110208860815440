<template>
  <section class="popup">
    <div class="popup__item">
      <div class="popup__header">
        <h6>Delivery Address</h6>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="closeModalHandler"
        >
          <path
            d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="popup__content">
        <form action="" @submit.prevent="handleSubmit">
          <div class="form__body">
            <label for="fullAddress">Full Address</label>
            <input
              id="fullAddress"
              placeholder="Enter delivery address"
              type="text"
              v-model="userAddress.address"
            />
            <span class="text-error" v-if="serverErrors?.address"
              >Please enter your full address!</span
            >

            <label for="">Phone Number</label>
            <input
              type="tel"
              v-model="userInfo.phone"
              placeholder="Enter Phone Number"
            />
            <span class="text-error" v-if="serverErrors?.phone_number">{{
              serverErrors?.phone_number?.length
                ? serverErrors?.phone_number[0]
                : "Your Phone Number must be 11 digits!"
            }}</span>

            <label for="selectState">Region</label>
            <v-select
              id="selectState"
              placeholder="Select Region"
              autocomplete="off"
              append-to-body
              :filterable="true"
              :value="userState.value"
              :options="statesQuery.data.value"
              :loading="isLoadingStates"
              :calculate-position="withPopper"
              @option:selected="onSelectState"
            >
              <template v-slot:no-options>
                <span></span>
              </template>
              <template v-slot:option="option">
                <div class="d-center">
                  {{ option.label }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div class="selected d-center">
                  {{ option.label }}
                </div>
              </template>
            </v-select>
            <span class="text-error" v-if="serverErrors?.state"
              >Please select your region!</span
            >

            <label for="selectCity">City</label>
            <v-select
              id="selectCity"
              placeholder="Select City"
              autocomplete="off"
              append-to-body
              :filterable="true"
              :value="userCity.value"
              :options="citiesQuery.data.value"
              :loading="isLoadingCities"
              :calculate-position="withPopper"
              @option:selected="onSelectCity"
            >
              <template v-slot:no-options>
                <span> Select region to see cities </span>
              </template>
              <template v-slot:option="option">
                <div class="d-center">
                  {{ option.label }}
                </div>
              </template>
              <template v-slot:selected-option="option">
                <div class="selected d-center">
                  {{ option.label }}
                </div>
              </template>
            </v-select>
            <span class="text-error" v-if="serverErrors?.city"
              >Please select your city!</span
            >

            <label for="">Postal Code</label>
            <input
              type="text"
              placeholder="eg: 200258"
              v-model="userAddress.postal_code"
            />
            <span class="text-error" v-if="serverErrors?.postal_code"
              >Please enter your Postal code!</span
            >
          </div>
          <PrimaryButton
            :loading="isSavingUserData"
            :disabled="isSavingUserData"
            :class="{ Loading: isSavingUserData }"
          >
            Save
          </PrimaryButton>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import api from "@/api";
import store from "@/store";

import { computed, defineProps, defineEmits, onMounted, ref, watch } from "vue";
import { success, fail } from "@/lib/toast";
import { useQuery } from "@tanstack/vue-query";
import { withPopper } from "@/lib/utils";

const props = defineProps(["isOpen", "name", "phone"]);
const emit = defineEmits(["cancelAddy"]);

const { data: userProfileData } = useQuery({
  queryKey: ["userProfile"],
  keepPreviousData: true,
  queryFn: async () => {
    let res = await api.get("user/profile");
    let profile = res.data.data;
    return { profile };
  },
});

const isMounted = ref(false);
const serverErrors = ref({});
const isSavingUserData = ref(false);
const userInfo = ref({
  fullname: "",
  phone: "",
});
const userAddress = ref({
  address: "",
  postal_code: "",
});
const userState = ref({
  state: "",
  state_code: "",
  value: null,
});
const userCity = ref({
  city: "",
  town_id: "",
  value: null,
});

const isLoadingStates = computed(
  () =>
    statesQuery.isLoading.value ||
    statesQuery.isFetching.value ||
    statesQuery.isRefetching.value
);

const isLoadingCities = computed(
  () =>
    citiesQuery.isLoading.value ||
    citiesQuery.isFetching.value ||
    citiesQuery.isRefetching.value
);

const onSelectState = (data) => {
  if (!data || !data.value) return;

  userState.value.state = data.label;
  userState.value.state_code = data.value.station_code;
  userState.value.value = data;

  delete serverErrors.value.state;
};

const onSelectCity = (data) => {
  if (!data || !data.value) return;

  userCity.value.city = data.label;
  userCity.value.town_id = data.value.deliverytownid;
  userCity.value.value = data;

  delete serverErrors.value.city;
};

const closeModalHandler = () => {
  store.state.editDelivery = false;
  serverErrors.value = {};
  emit("cancelAddy", !props.isOpen);
};

const handleSubmit = async () => {
  isSavingUserData.value = true;
  serverErrors.value = {};

  const payload = {
    address: userAddress.value.address,
    postal_code: userAddress.value.postal_code,
    state: userState.value.state,
    state_code: userState.value.state_code,
    city: userCity.value.city,
    town_id: userCity.value.town_id,
    country: "Nigeria",
    phone_number: userInfo.value.phone?.trim(),
  };

  try {
    await api.put("user/address/delivery", payload);

    success("Info updated");
    closeModalHandler();
  } catch (err) {
    if (!err.response.data.errors) {
      fail(err.response.data.message);
    } else {
      serverErrors.value = err.response.data.errors;
      fail("An error occurred while saving info!");
    }
  } finally {
    isSavingUserData.value = false;
  }
};

// TODO: REFACTOR TO LOGISTICS SERVICES
const getStates = async () => {
  const res = await api.get("logistics/states");

  return res.data.data?.map((state) => {
    return {
      label: state.station_description,
      value: state,
    };
  });
};

// TODO: REFACTOR TO LOGISTICS SERVICES
const getCities = async () => {
  if (!userState.value.state_code) return [];

  const response = await api.get(
    `logistics/${userState.value.state_code}/cities`
  );

  const selectedCity = response.data.data?.find(
    (city) => city.deliverytowncode === userCity.value.city
  );

  if (!selectedCity) {
    userCity.value = {
      state: "",
      state_code: "",
      value: null,
    };
  }

  return response.data.data?.map((city) => {
    return {
      label: city.deliverytowncode,
      value: city,
    };
  });
};

const statesQuery = useQuery({
  queryKey: ["deliverstateproduct"],
  queryFn: getStates,
  retry: 1,
  keepPreviousData: true,
  initialData: [],
});

const citiesQuery = useQuery({
  queryFn: getCities,
  queryKey: ["deliver-city-product", userState],
  retry: 1,
  keepPreviousData: false,
  enabled: isMounted,
  initialData: [],
});

watch(userState, citiesQuery.refetch);

onMounted(async () => {
  isMounted.value = true;

  const userDeliverAddress =
    userProfileData.value?.profile?.addresses?.delivery_address;

  userInfo.value.fullname = userProfileData.value.profile.fullname;
  userInfo.value.phone = userProfileData.value.profile.phone_number;

  if (!userDeliverAddress) return;

  userAddress.value.address = userDeliverAddress.address;
  userAddress.value.postal_code = userDeliverAddress.postal_code;

  userState.value.state = userDeliverAddress.state;
  userState.value.state_code = userDeliverAddress.state_code;
  userState.value.value = {
    label: userDeliverAddress.state,
  };

  userCity.value.city = userDeliverAddress.city;
  userCity.value.town_id = userDeliverAddress.town_id;
  userCity.value.value = {
    label: userDeliverAddress.city,
  };
});
</script>

<style scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000000;
}
.popup__item {
  width: min(95%, 444px);
  border: 0.5px;
  background-color: #ffffff;
  padding: 2rem 2rem !important;
  max-height: 95vh;
}
button[disabled] {
  filter: opacity(0.5);
}
.popup__header {
  display: flex;
  justify-content: space-between;
}
.popup__header h6 {
  font-size: Max(1.25rem, 16px);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0em;
  color: #002e22;
}
.popup__header svg {
  cursor: pointer;
}
label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #434343;
  margin-top: 1rem;
}
input,
.vs__dropdown-toggle {
  width: 100%;
  height: 48px;
  padding: 0.75rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69);
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 1 !important;
}

.vs--searchable .vs__dropdown-toggle {
  padding: 0.7rem !important;
}

input::placeholder,
.vs__search::placeholder {
  font-size: Max(1rem, 16px);
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0em;
  color: rgba(69, 69, 69, 0.75) !important;
}

button {
  width: 96px;
  height: 40px;
  border-radius: 4px;
  padding: 1rem 2rem;
  background-color: #006e52;
  text-align: center;
  font-size: Max(1rem, 16px);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0em;
  color: white;
  text-align: center;
  outline: none;
  margin-top: 1rem;
  border: none;
  display: flex;
  align-items: center;
}
.dropdown {
  position: relative;
}
.dropdown input {
  position: relative;
}
.dropdown__bottom {
  position: absolute;
  background-color: #fff;
  max-height: 150px;
  width: 100%;
  overflow: auto;
  text-align: left;
  padding: 0.5rem;
  z-index: 3;
}
.dropdown__bottom__item {
  display: flex;
  flex-direction: column;
}
.dropdown__bottom span {
  border-bottom: 1px solid #f9f9f9;
  margin: 0.35rem;
}

.text-error {
  padding: 3px 0;
  display: inline-block;
  font-size: 0.9rem;
}

.form__body::-webkit-scrollbar {
  width: 3px;
}

@media only screen and (max-height: 900px) {
  .form__body {
    height: 70vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .popup__item {
    min-height: 426px;
    max-height: unset;
  }
}

@media only screen and (max-width: 600px) {
  .popup__item {
    padding: 1.5rem !important;
  }
}
</style>
