import Vue from "vue";
import VueRouter from "vue-router";
import HomeRoutes from "@/modules/home/router";
import UserRoutes from "@/modules/user-dashboard/router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Error Page",
    component: () => import("@/Errors/404.vue"),
    meta: {
      layout: "defaultLayout",
    },
  },
  {
    path: "/offline",
    name: "Offline Page",
    component: () => import("@/Errors/Offline.vue"),
    meta: {
      layout: "defaultLayout",
    },
  },
  {
    path: "/verify-link-email",
    name: "verificaton page",
    component: () => import("@/Errors/Verify.vue"),
    meta: {
      layout: "defaultLayout",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/Errors/Reset.vue"),
    meta: {
      layout: "defaultLayout",
    },
  },
  {
    path: "/appeal-suspension",
    name: "appeal-suspension",
    component: () => import("@/Errors/AppealAccount.vue"),
    meta: {
      layout: "defaultLayout",
    },
  },
];

/**
 * App Router
 *
 * reset scroll position on new page.
 */
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.concat(HomeRoutes, UserRoutes),
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

/**
 * Handle unauthorised users
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (window.sessionStorage.getItem("user") === "authenticated") {
      next();
      return;
    } else {
      next({
        path: from.fullPath,
        query: { redirectFrom: to.fullPath },
      });
      store.state.AccessState = 1;
    }
  } else {
    next();
  }
});

/**
 * handling authorized user
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (window.sessionStorage.getItem("user") === "authenticated") {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
