import { getCache } from "@/lib/affiliate";
import { addToCart } from "@/lib/utils";
import { _SELECT_PRODUCT_VARIANT, _TOGGLE_IS_ADDING_PRODUCT } from ".";
import { fail } from "@/lib/toast";

export const ADD_PRODUCT_TO_CART = "cartModule/ADD_PRODUCT_TO_CART";
export const GET_ITEMS = "cartModule/GET_ITEMS";

const _ADD_PRODUCT_TO_CART = ADD_PRODUCT_TO_CART.replace("cartModule/", "");

const cartModuleActions = {
  async [_ADD_PRODUCT_TO_CART]({ commit }, { product, variants }) {
    const defaultVariant = {
      colors: product.colors?.[0],
      sizes: product.sizes?.[0],
      quantity: 1,
    };

    if (!defaultVariant.colors) {
      delete defaultVariant.colors;
    }

    if (!defaultVariant.sizes) {
      delete defaultVariant.sizes;
    }

    if (
      (product.sizes?.length > 1 || product.colors?.length > 1) &&
      !variants
    ) {
      product.variant = defaultVariant;

      commit(_SELECT_PRODUCT_VARIANT, product);
      return;
    }

    const cartItem = {
      slug: product.slug,
      quantity: 1,
      affiliate_id: getCache("affiliate"),
      variant: variants ? variants : [defaultVariant],
    };

    commit(_TOGGLE_IS_ADDING_PRODUCT, product.uuid);

    try {
      await addToCart(cartItem);
    } catch (error) {
      fail("An error occurred while adding product");
    } finally {
      commit(_TOGGLE_IS_ADDING_PRODUCT, product.uuid);
    }
  },
};

export default cartModuleActions;
