<template>
  <div class="home">
    <!-- <CheckoutSaveOn /> -->
    <DeliveryAddress v-if="$store.state.editDelivery" />
    <Login v-if="$store.state.AccessState === 1" />
    <CreateAccount v-if="$store.state.AccessState === 2" />
    <ForgotPassword v-if="$store.state.AccessState === 3" />
    <Reset v-if="$store.state.AccessState === 4" />
    <CreatePin v-if="$store.state.AccessState === 5" />
    <SupportModal v-if="$store.state.AccessState === 6" />
    <Logout v-if="$store.state.logout" />
    <SaveOff v-if="$store.state.saveOn === true">
      Spend & Save is now off. Don’t miss out on rewards and savings- turn it
      back on to maximize your benefits.
    </SaveOff>
    <SaveOn v-if="$store.state.saveOff === true">
      You’re saving smart! Remember Our Spend & Save feature helps you earn
      rewards and stay on top of your finances.
    </SaveOn>
    <SelectProductVariant
      v-if="$store.state.cartModule.selectProductVariant"
      :product="$store.state.cartModule.selectProductVariant"
    />
    <orderTracking v-if="$store.state.tracking === true">
      Sorry! we can not track orders at this moment
    </orderTracking>
    <AppLayout>
      <router-view />
    </AppLayout>
    <Cookie />
  </div>
</template>

<script setup>
import AppLayout from "./layouts/AppLayout.vue";
import CreateAccount from "./Modals/CreateAccount.vue";
import Login from "./Modals/Login.vue";
import ForgotPassword from "./Modals/ForgotPassword.vue";
import Reset from "./Modals/Reset.vue";
import Cookie from "./components/notifications/cookie.vue";
import CreatePin from "./Modals/createPin.vue";
import Logout from "./Modals/Logout.vue";
import SaveOn from "@/components/notifications/SaveOn.vue";
import SaveOff from "@/components/notifications/SaveOff.vue";
import DeliveryAddress from "./Modals/deliveryAddress.vue";
import SelectProductVariant from "@/Modals/selectProductVariant.vue";
import SupportModal from "@/Modals/SupportModal.vue";
import useSavedItems from "./composables/useSavedItems";

import { onMounted } from "vue";
import { getCartItems, getVisitorsJwt } from "./lib/utils";

// Fetch saved items and add to state
useSavedItems({ fetchItems: true });

onMounted(async () => {
  if (process.env.VUE_APP_INSTALL_TAG_MANAGER === `production`) {
    const script = document.createElement("script");
    script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-WSDW4B29');`;
    document.head.appendChild(script);
  }

  const existingJwt = window.localStorage.getItem("jwt");
  const isAuthenticated =
    window.sessionStorage.getItem("user") === "authenticated";

  if (!existingJwt && !isAuthenticated) {
    await getVisitorsJwt();
  }

  await getCartItems();
});
</script>

<style scoped>
.home {
  position: relative;
}
</style>
