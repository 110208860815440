import { render, staticRenderFns } from "./ProductCardMini.vue?vue&type=template&id=b1d90d98&scoped=true"
import script from "./ProductCardMini.vue?vue&type=script&setup=true&lang=js"
export * from "./ProductCardMini.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ProductCardMini.vue?vue&type=style&index=0&id=b1d90d98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1d90d98",
  null
  
)

export default component.exports